<template>
  <section class="new_css">
    <div style="display:flex;margin-top:5px;margin-left:5px">
      <el-select @change="handleTypeChange" v-model="typeValue" placeholder="请选择常用地点">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <!--工具条-->
      <toolbar
        :buttonList="buttonList"
        @callFunction="callFunction"
        :buttonListmsg="RegularMeetingmsg"
      ></toolbar>
    </div>
    <!--列表内容-->
    <el-table
      :data="meetings"
      highlight-current-row
      @current-change="selectCurrentRow"
      :row-style="{height:'40px'}"
      :cell-style="{padding:'0px'}"
      :header-cell-style="{background:'#dce4f1',color:'#000000'}"
      :row-class-name="tableRowClassName"
      style="width: 100%">
      <el-table-column type="index"></el-table-column>
      <el-table-column label="常用人员姓名" prop="PersonName" align="center"></el-table-column>
      <el-table-column label="常用地点" prop="Type" align="center" min-width="120" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.Type==1" >详细任务查询</el-tag>
          <el-tag v-else-if="scope.row.Type==2" >WT使用概览报表</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="创建人" prop="CreatedBy" align="center" min-width="50"></el-table-column>
      <el-table-column label="创建时间" prop="CreateTime" align="center" min-width="125"></el-table-column>
      <!-- <el-table-column label="操作" align="center" min-width="60px">
        <template slot-scope="scope">
            <el-row  >
              <el-button type="text" size="medium" @click="handleLook(scope.row)">查看</el-button>
            </el-row>
        </template>
      </el-table-column> -->
    </el-table>
    <br/>
    <!--新增界面-->
    <el-dialog
      :visible.sync="addFormVisible"
      v-model="addFormVisible"
      @close="diaclose"
      center
    >
    <template slot="title">
        <span style="color:#FFFFFF;font-size: 16px;">新增常用人员</span>
    </template>
      <el-form
        :model="addForm"
        label-width="85px"
        :rules="addFormRules"
        ref="addForm"
        style="white-space:nowrap;"
      >
      
        <el-row>
          <el-col :span="22">
            <el-form-item label="常用人员:" prop="UsePeopleName">
              <el-input v-model="addForm.UsePeopleName" placeholder="请选择常用人" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-button type="text">
              <userchoosetool v-show="showChooseUser" @usercallFunction="usercallFunction" :callbackmsg="doublepeople"></userchoosetool>
            </el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="常用地点:">
              <el-col :span="6">
                <el-radio-group v-model="addForm.Type">
                    <el-radio :label="1">详细任务查询</el-radio>
                    <el-radio :label="2">WT使用概览报表</el-radio>
                </el-radio-group>
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="goon" @click.native="addSubmit">提交</el-button>
      </div>
    </el-dialog>
    
    


        <el-dialog
        title="选择人员"
        :visible.sync="addUserVisible"
        v-model="addUserVisible"
        width="75%"
        center
        append-to-body
        >
        <UserChoose
            :data="choosedusers"
            :all="true"
            :single="false"
            @callback="chooseUserCallBack"
        ></UserChoose>
        </el-dialog>


        <el-dialog
        title="选择人员"
        :visible.sync="addUserVisible2"
        v-model="addUserVisible2"
        width="75%"
        center
        append-to-body
        >
        <UserChoose
            :data="choosedusers"
            :all="true"
            :single="false"
            @callback="chooseUserCallBack2"
        ></UserChoose>
        </el-dialog>

        <el-dialog title="选择部门" :visible.sync="addDptVisible" @opened="handleDptOpened"
         v-model="addDptVisible" width="75%" center append-to-body @closed="handleDptClosed" >
         
          <el-tree
            :data="alldepartmentdata"
            show-checkbox
            :expand-on-click-node="false"
            :default-expand-all="false"
            :check-strictly="isAll"
            :check-on-click-node="true"
            highlight-current
            :indent="30"
            node-key="id"
            ref="tree"
          ></el-tree>

            <span slot="footer" class="dialog-footer">
              <el-button @click="addDptVisible = false">取 消</el-button>
              <el-button type="primary" @click="handleConfirmTree">确 定</el-button>
            </span>
        </el-dialog>

  </section>
</template>
<script>
import util from "../../../util/date";
import {
  QueryMeetingUphold,
  GetUserPostList,
  AddSearchQuanxian,
  AddGroupPersonItem,
  QueryAllSearchQuanxianList,
  FormatDepartment,
  DeleteSearchQuanxianById,
  DeleteGroupPersonById,
  UpdateSearchQuanxian,
  UpdateMeetingUphold,
  UpdateMeetingUpholdCCUser,
  QueryGroupPersonListByCreatedUser
} from "../../api/oa";
import {GetDepartmentTree, GetFormatDepartmentList} from "../../api/hr";
import {Loading} from 'element-ui'
import { getButtonList } from "../../promissionRouter";
import Userchoosetool from "../../components/Userchoosetool";
import Toolbar from "../../components/Toolbar";
import UserChoose from "../../components/UserChoose";
export default {
  components: { Toolbar, Userchoosetool,UserChoose },
  data() {
    return {
      typeValue:1,
      options:[
        {
          value:1,
          label:'详细任务查询'
        },
        {
          value:2,
          label:'WT使用概览报表'
        }
      ],
      loading: '',
      meetings: [],
      buttonList: [],
      filters: {
        name: ""
      },
      ccusername:'',
      ccusername2:'',
      chooseDptId:'',
      chooseDptName:'',
      userid: [], //暂存选中角色下所有用户的id
      choosedusers: [],//抄送人
      addUserVisible:false,
      addUserVisible2:false,
      addDptVisible:false,
      addFormVisible: false,
      editFormVisible: false,
      editCCFormVisible: false,
      showChooseUser:true,
      departmsg: "departmsg",
      catchdepart: null,
      currentRow: null,
      Meetingstatus: "每天",
      // Status:'1',
      RegularMeetingmsg: "groupperson",
      assignpeo: "assignpeo",
      doublepeople: [],
      addForm: {
        Id:'',
        UsePeopleName: null,
        UsePeopleCode: null,
        Type:1,
        CreatedId: null,
        CreatedBy: null,
        DepartmentId:'',
        DepartmentName:'',
        Status:0,
        Remark: null
      },
      addFormRules: {
        UsePeopleName: [
          { required: true, message: "请选择团队成员", trigger: "blur" }
        ],
        DepartmentName: [
          { required: true, message: "请选择授权查阅部门", trigger: "blur" }
        ]
      },
      editForm: {
        Id: null,
        ApprovalName: null,
        ApprovalCode: null
      },
      editCCForm: {
        Id: null,
        CCUserName: null,
        CCUserCode: null
      },
      alldepartmentdata: [],
      isAll: false,
      TreeProps: {
        children: "children",
        label: "lable",
        CascadeId: "cascadeId"
      },
      treeSelected:[]
    };
  },
  methods: {
    handleTypeChange(e){
      console.log(e)
      this.getGroupPersonList();
    },
    queryAllDepartment()
    {
      GetDepartmentTree().then((res) => {
        // console.log(res);
        if (res.data.code==200) {
          this.alldepartmentdata = res.data.data.dept;
        }
      });
    },
    handleChooseCCUser()
    {
        this.addUserVisible=true;
    },
    handleChooseCCUser2()
    {
        this.addUserVisible2=true;
    },
    handleDptClosed()
    {
      this.treeSelected=new Array();
    },
    handleDptOpened()
    {
      if(this.treeSelected.length>0)
      {
        console.log(this.treeSelected.length);
        // this.$refs.tree.setCheckedKeys(this.treeSelected,true);
        // console.log(this.$refs.tree);
        this.$refs.tree.setCheckedKeys(this.treeSelected,true);
      }
    },
    handleChooseDepartment()
    {
      this.addDptVisible=true;
      // this.$refs.tree.setCheckedKeys([]);
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBack2(data) {
        console.log(data);
        var newdata = data.map(function (item) {
            return item["Id"];
        });
        var namenewdata = data.map(function (item) {
            return item["UserNameClaim"];
        });
        console.log(newdata);

        var str = "";
        for (var i = 0; i < namenewdata.length; i++) {
            str += namenewdata[i]+ ",";
        }
        if (str.length > 0) {
            str = str.substr(0, str.length - 1);
        }

        var idstr = "";
        for (var i = 0; i < newdata.length; i++) {
            idstr += newdata[i]+ ",";
        }
        if (idstr.length > 0) {
            idstr = idstr.substr(0, idstr.length - 1);
        }
        this.ccusername2=str;
        this.editCCForm.CCUserCode = idstr;
        this.editCCForm.CCUserName = str;
        this.addUserVisible2=false;
    },
    //监听子组件事件 选择人员回调
    chooseUserCallBack(data) {
        console.log(data);
        var newdata = data.map(function (item) {
            return item["Id"];
        });
        var namenewdata = data.map(function (item) {
            return item["UserNameClaim"];
        });
        console.log(newdata);

        var str = "";
        for (var i = 0; i < namenewdata.length; i++) {
            str += namenewdata[i]+ ",";
        }
        if (str.length > 0) {
            str = str.substr(0, str.length - 1);
        }

        var idstr = "";
        for (var i = 0; i < newdata.length; i++) {
            idstr += newdata[i]+ ",";
        }
        if (idstr.length > 0) {
            idstr = idstr.substr(0, idstr.length - 1);
        }
        this.ccusername=str;
        this.addForm.CCUserCode = idstr;
        this.addForm.CCUserName = str;
        this.addUserVisible=false;
    },
    callFunction(item) {
      this.filters = {
        name: item.search
      };
      this[item.Func].apply(this, item);
    },
    formatCreatedOn: function(row, colume) {
      return !row.LastUpdateTime || row.LastUpdateTime == ""
        ? ""
        : util.formatDate.format(new Date(row.LastUpdateTime), "yyyy-MM-dd");
    },
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    handleConfirmTree()
    {
      var treeData = this.$refs.tree.getCheckedNodes();
      // console.log(treeData);
      var dptStr="";
      var dptId="";
      var dptCasId="";
      for(var a=0;a<treeData.length;a++)
      {
        // console.log(treeData[a]);
        dptStr+=treeData[a].label;
        dptId+=treeData[a].id;
        dptCasId+=treeData[a].cascadeId;
        dptStr+=",";
        dptId+=",";
        dptCasId+="|";
      }
      GetFormatDepartmentList({
        deptIds:dptId
      }).then(res => {
        dptStr="";
        dptId="";
        dptCasId="";
        var formatTreeData=res.data.data;
        for(var a=0;a<formatTreeData.length;a++)
        {
          // console.log(formatTreeData[a]);
          dptStr+=formatTreeData[a].deptName;
          dptId+=formatTreeData[a].deptId;
          // dptCasId+=formatTreeData[a].cascadeId;
          dptStr+=",";
          dptId+=",";
          // dptCasId+=",";
        }
        this.chooseDptName=dptStr;
        this.chooseDptId=dptId;
        this.addForm.DepartmentId=dptId;
        this.addForm.DepartmentName=dptStr;
        this.addDptVisible=false;
      });
    },
    handleLook(row)
    {
      this.addForm = Object.assign({}, row);
      this.addForm.UsePeopleCode=row.UserCode;
      this.addForm.UsePeopleName=row.UserName;
      
      this.addForm.DepartmentId=row.DepartmentCode;
      
      this.showChooseUser=false;
      // console.log(this.addForm)
      this.addForm.Type=row.Type;
      this.addForm.Status=row.Deleted;
      this.addFormVisible=true;
      var myarr = row.DepartmentCode.split(',');
      this.treeSelected=[];
      for(var a=0;a<myarr.length;a++)
      {
        if(myarr[a].length!=0)
        {
          this.treeSelected.push(myarr[a]);
        }
      }
      // console.log(this.treeSelected);
      this.queryAllDepartment();
      // console.log(row)
      // alert('s')
    },
    handleQuery()
    {
      this.getGroupPersonList();
    },
    getGroupPersonList() {
      let self = this;
      var user = JSON.parse(window.localStorage.user);
      QueryGroupPersonListByCreatedUser({
          userCode: user.sub,
          type: self.typeValue
        }).then(res => {
          this.meetings = res.data.response;
        });
    },
    changeStatus(s)
    {
      //console.log(s);
      // if(s==0)
      // {
      //   this.addForm.Status=1;
      //   //alert('a')
      // }else{
      //   this.addForm.Status=0;
      // }
      this.$forceUpdate();
    },
    handleChangeStatus()
    {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要调整的一行数据！",
          type: "error"
        });
        return;
      }
      console.log(row);
      var status='';
      var statusVal=0;
      if(row.Deleted==1)
      {
        status='启用';
        statusVal=0;
      }else if(row.Deleted==0)
      {
        status='禁用';
        statusVal=1;
      }
      // alert(status)
      this.$confirm("确认要将授权状态调整为"+status+"吗？", "提示", {}).then(() => {
        this.Loadingstart();
        let para = { searchQuanxianId: row.Id,status:statusVal };
        DeleteSearchQuanxianById(para).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.currentRow = null;
            this.getGroupPersonList();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
          }
        });
      });
    },
    //新增
    handleAdd() {
      this.addFormVisible = true;
      this.showChooseUser=true;
      this.addForm.Id='';
      this.treeSelected = [];
    },
    //新增提交
    addSubmit() {
      let self = this;
      let para = Object.assign({}, this.addForm);
      var user = JSON.parse(window.localStorage.user);
      // para.CreatedId = user.sub;
      // para.CreatedBy = user.name;
      var codeArr = para.UsePeopleCode.split(',');
      var nameArr = para.UsePeopleName.split(',');
      var arr = new Array();
      for(var a=0;a<codeArr.length;a++){
        if(codeArr[a]==''){
          continue;
        }
        arr.push({
          personUserId: codeArr[a],
          personName: nameArr[a],
          createdId: user.sub,
          createdBy: user.name,
          type: self.addForm.Type
        })
      }
      // console.log(arr);
      // return;
      if(para.UsePeopleCode==null)
      {
        this.$message({
          message: "请选择授权人",
          type: "warning"
        });
        return;
      }
      //alert('submit');
      this.Loadingstart();
      AddGroupPersonItem(arr).then(res => {
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: "success"
          });
          this.Loadignend();
          this.$refs["addForm"].resetFields();
          this.addDptVisible = false;
          this.addFormVisible = false;
          this.getGroupPersonList();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error"
          });
        }
      });
    },
    //删除
    handleDel() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "warning"
        });
        return;
      }
      this.$confirm("确认移除选中人员吗？", "提示", {}).then(() => {
        this.Loadingstart();
        let para = { id: row.Id };
        DeleteGroupPersonById(para).then(res => {
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: "success"
            });
            this.Loadignend();
            this.currentRow = null;
            this.getGroupPersonList();
          } else {
            this.$message({
              message: res.data.msg,
              type: "error"
            });
          }
        });
      });
    },
    diaclose() {
      this.$refs.addForm.resetFields();
    },
    tipMessage() {
      this.$message({
        message: "填写未完成，请继续填写",
        type: "warning"
      });
    },
    //部门选择回调
    departcallFunction(departname) {
      console.log(departname);
      this.catchdepart = departname;
      this.addForm.DepartmentName=departname.Name;
      this.chooseDptName=departname.Name;
    },
    callDpt()
    {
      this.addDptVisible=false;
    },
    //审核人回调
    assigncallFunction(newdata) {
      this.addForm.ApprovalCode = newdata[0];
      this.editForm.ApprovalCode = newdata[0];
      let para = { userId: newdata[0] };
      GetUserPostList(para).then(res => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.addForm.ApprovalName = res.data.data[0].name;
          this.editForm.ApprovalName = res.data.data[0].name;
        }
      });
    },
      tableRowClassName({row, rowIndex}){
            if (rowIndex % 2 !== 0) {
            return 'warning-row';
            }
            return '';    
       },
       //会议发布人回调
       usercallFunction(newdata){
          let fabuusers = "";
          let fabuusercodes = "";
         if(newdata.length == 1){
           this.addForm.UsePeopleCode = newdata[0];
           let para = {userId: newdata[0]};
            GetUserPostList(para).then((res) => {
                    this.addForm.UsePeopleName = res.data.data[0].name;
            });
          }else{
            newdata.map(item => {
              fabuusercodes += item + ",";
              this.addForm.UsePeopleCode = fabuusercodes.replace("null", "");
              let para = {userId: item};
              GetUserPostList(para).then((res) => {
                      fabuusers += res.data.data[0].name + ",";
                      this.addForm.UsePeopleName =  fabuusers.replace("null", "");
                      this.addForm.UsePeopleName = this.addForm.UsePeopleName.substr(0, this.addForm.UsePeopleName.length - 1);
              });
            })
          }
       },
       Loadingstart(){
        　　this.loading = Loading.service({
        　　　　lock:true,
        　　　　text:'加载中...',
        　　　　background:'rgba(0,0,0,0.1)'
        　　})
        },
        Loadignend(){
        　　this.loading.close();
        }
  },
  mounted() {
    this.getGroupPersonList();
    this.queryAllDepartment();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // this.getButtonList(routers);
    
    //第二种写法，封装到 permissionRouter.js 中
    let  buttons = window.localStorage.buttList? JSON.parse(window.localStorage.buttList) : [];
    this.buttonList = getButtonList(this.$route.path, routers,buttons);
  }
};
</script>
<style scoped>
/deep/.el-table__body tr.current-row > td {
  background: #fabe64 !important;
}
/deep/.el-table .warning-row {
    background: #F0F8FF;
  }
/deep/.el-dialog__header{
    padding:20px 20px 10px;background-color: #20B2AA;
}
/deep/.el-icon-close:before{
    color: #FFFFFF;
}
</style>